import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationUser } from 'src/app/models/application-user/application-user';
import { ApplicationUserService } from 'src/app/core/services/application-user.service';
import { merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { Branch } from 'src/app/models/branch/branch';
import {debounceTime, distinctUntilChanged, filter, ignoreElements, map, switchMap} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead.module';
import { DataService } from 'src/app/services/data.service';
import { SelectItem } from 'src/app/core/models/responses/select-item';
import { LanguageService } from 'src/app/core/services/language.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerCreateEditModalComponent } from '../../components/customers/customer-create-edit-modal/customer-create-edit-modal.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
  @ViewChild('profileImage') profileImage;
  element;
  searching: boolean;
  user: ApplicationUser;
  currentBranch: Branch = JSON.parse(localStorage.getItem('currentBranch'));
  subSystemName: string = localStorage.getItem('subSystemName');
  private _playSound: boolean = localStorage.getItem('playSound') === 'true';

  get playSound(): boolean {
    return this._playSound;
  }

  set playSound(value: boolean) {
    this._playSound = value;
    localStorage.setItem('playSound', value.toString());
  }

  

  model: SelectItem[];

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  constructor(
    private router: Router,
    private appUserService: ApplicationUserService,
    public languageService: LanguageService,
    private dataService: DataService,
    private modalService: NgbModal,
    public _cookiesService: CookieService,
    private alertService: AlertService) {
      appUserService.currentUser.subscribe(res=> this.user = res);
  }

  openMobileMenu: boolean;

  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.appUserService.currentBranch.subscribe(res => {
      this.currentBranch = res;
    })
  }
  selectedCustomer(customer){
    this.model = null;
    this.router.navigate(['customer/detail/' + customer.item.value]);
    customer.preventDefault();
  }
  resultFormatBandListValue(value: SelectItem) {
    return value.text;
  }
  inputFormatBandListValue(value: SelectItem)   {
    if(value.text)
      return value.text
    return value;
  }
  search = (text$: Observable<string>) =>

    text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => term ? this.dataService.getCustomer({ name: term, isActive: true}).toPromise().then(res=> res.data) : [])
    )
  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }


  logout() {
    if (environment.defaultauth === 'znobackend') {
      this.appUserService.logout();
    }

    this.router.navigate(['/login']);
  }

  changeBranch(branch: Branch){
    this.appUserService.changeBranch(branch);
    this.currentBranch = branch;
    this.router.navigateByUrl('/dashboard')
  }

  openCustomerModal(){
    const modalRef = this.modalService.open(CustomerCreateEditModalComponent);
  }
  toggleSounds(){
    console.log(this.playSound);
    this.playSound = !this.playSound;
    this.alertService.showSuccessToast('Sesler ' + (this.playSound ? 'açıldı' : 'kapatıldı'));
  }
}
