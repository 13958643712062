<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Kasa Güncelle</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="mb-3">
            <label for="caseName">Kasa Adı</label>
          <div class="input-group">
            <input id="caseName" class="form-control" type="text" formControlName="caseName" [(ngModel)]="model.name">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="submit(); modal.close('Save click'); ">Kaydet</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Geri Dön</button>
    </div>
  </ng-template>

  <button class="btn btn-sm btn-warning mb-2" *hasRole="[Role.Admin, Role.SuperAdmin]" (click)="open(content)"> <i class="fa fa-pen"></i> Güncelle</button>