import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseCreateModalModule } from './case-create-modal/case-create-modal.module';
import { CaseTransferModalModule } from './case-transfer-modal/case-transfer-modal.module';
import { AllCaseBalancesExportModule } from './all-case-balances-export/all-case-balances-export.module';
import { CaseEditModalComponent } from './case-edit-modal/case-edit-modal.component';
import { CaseEditModalModule } from './case-edit-modal/case-edit-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CaseCreateModalModule,
    CaseEditModalModule,
    CaseTransferModalModule,
    AllCaseBalancesExportModule
  ]
})
export class CasesModule { }
