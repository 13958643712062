import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { Case } from 'src/app/models/case/case';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-case-edit-modal',
  templateUrl: './case-edit-modal.component.html',
  styleUrls: ['./case-edit-modal.component.scss']
})

export class CaseEditModalComponent extends BaseComponent implements OnInit {
  @Input() case: Case;

  form!: FormGroup;
  isDisabled: boolean = false;
  currentBranch = JSON.parse(localStorage.getItem('currentBranch'));
  constructor(
    private modalService: NgbModal,
    private service: CaseService,
    private alertService: AlertService

  ) {
    
    super();
  }

    model: Case = {
      branchId: this.currentBranch.id,
      name: '',
      isDefault: false
    }

  ngOnInit(): void {
    this.form = new FormGroup({
      caseName: new FormControl('', [Validators.required]),
    });

  }

  get f(){
    return this.form.controls;
  }

  open(content) {
    this.model = this.case;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {}, (reason) => {});
  }

  submit(){
    this.model.name = this.f.caseName.value;
    this.isDisabled = true;
    this.service.update(this.model).toPromise().then(res => {
      this.isDisabled = false;
      if(res.isSuccess){
        this.form.reset();
        this.service.refreshIndex.next('');
        this.alertService.showSuccess('Kasa güncellendi.');
      }
    }).catch(err => this.isDisabled = false);
  }

}
