import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseRoutingModule } from './case-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CaseIndexComponent } from './case-index/case-index.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { AccountTransactionListModule } from '../../components/account-transactions/account-transaction-list/account-transaction-list.module';
import { CaseCreateModalModule } from '../../components/cases/case-create-modal/case-create-modal.module';
import { CaseCountingComponent } from './case-counting/case-counting.component';
import { KeyToSubTypePipe } from 'src/app/shared/pipes/key-to-sub-type.pipe';
import { CoreModule } from 'src/app/core/core.module';
import { CaseTransferModalModule } from '../../components/cases/case-transfer-modal/case-transfer-modal.module';
import { CaseEditModalComponent } from '../../components/cases/case-edit-modal/case-edit-modal.component';
import { CaseEditModalModule } from '../../components/cases/case-edit-modal/case-edit-modal.module';


@NgModule({
  declarations: [
    CaseIndexComponent,
    CaseCountingComponent,
  ],
  imports: [
    CommonModule,
    CaseRoutingModule,
    NgbNavModule,
    PipesModule,
    FormsModule,
    CoreModule,
    SharedComponentsModule,
    AccountTransactionListModule,
    CaseCreateModalModule,
    CaseEditModalModule,
    CaseTransferModalModule
  ],
  exports: [],
  providers: [KeyToSubTypePipe]
})
export class CaseModule { CaseCreateComponent }
