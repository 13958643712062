import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { isEmpty, isNullOrUndefined } from '../core/helpers/common-functions';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  get playSound(): boolean {
    return localStorage.getItem('playSound') === 'true';
  }

  constructor() { }
  playErrorSound() {
    if(this.playSound){
      const audio = new Audio('assets/sounds/error.mp3');
      audio.play();
    }
  }
  playSuccessSound() {
    if(this.playSound){
      const audio = new Audio('assets/sounds/success.mp3');
      audio.play();
    }
  }
  showSuccess(message?: string){
    let _message;
    message != null ? _message = message : _message = 'İşlem başarıyla gerçekleştirildi!'
    Swal.fire({
      title: 'Başarılı',
      text: _message,
      icon: 'success',
      confirmButtonText: 'Tamam'
     })
     this.playSuccessSound();
  }

  showSuccessToast(message?: string){
    let _message;
    message != null ? _message = message : _message = 'İşlem başarıyla gerçekleştirildi!'
    const toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      timer: 3000,
     })

     toast.fire({
      icon: 'success',
      title: _message,
      showConfirmButton: false
     });
     this.playSuccessSound();
  }
  showErrorToast(message?: string){
    let _message;
    message != null ? _message = message : _message = 'İşlem gerçekleştirilemedi.'
    const toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      timer: 5000,
     })

     toast.fire({
      icon: 'error',
      title: _message,
      showConfirmButton: false
     })
     this.playErrorSound();
  }

  showErrorsToast(messages: string[]){
    let _message = '';
    messages.map(p => _message += p + ' \n');
    const toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      timer: 5000,
     })

     toast.fire({
      icon: 'error',
      title: _message,
      showConfirmButton: false
     });
     this.playErrorSound();
  }

  showError(message?: string){
    let _message;
    message != null ? _message = message : _message = 'İşlem gerçekleştirilirken bir hata meydana geldi.'
    Swal.fire({
     title: 'Hata',
     text: _message,
     icon: 'error',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Tamam',
      cancelButtonColor: '74788D'
    })
    this.playErrorSound();
  }

  showInfo(message: string){
    Swal.fire({
      title: 'Bilgilendirme',
      text: message,
      icon: 'info',
      confirmButtonText: 'Tamam'
     })
  }


  showInfoToast(message: string){
    const toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      timer: 5000,
     })

     toast.fire({
      icon: 'info',
      title: message,
      showConfirmButton: false
     })
  }

  showValidation(message: any){
    if (isEmpty(message))
      return;

    var html = "<ul class='list-group'>";
    for (var k in message){
        if (message.hasOwnProperty(k)) {
            html += "<li style='border: none;' class='list-group-item text-danger'>";
            if(k)
             html += k +": ";
            html += message[k]+"</li>";
        }
    }
    html += "</ul>"
    Swal.fire({
      title: 'İşlem gerçekleştirilemedi.',
      text: message,
      html: html,
      icon: 'info',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: '#74788D',
      cancelButtonText: 'Geri Dön'
     })
  }


  showWarning(message: string){
    Swal.fire({
     title: 'Uyarı',
     text: message,
     icon: 'warning',
     showConfirmButton: false,
     showCancelButton: true,
     cancelButtonColor: '#74788D',
     cancelButtonText: 'Anladım'
    })
  }

  showDeleteAlert(): Promise<SweetAlertResult<any>>{
    const swalRef = Swal.fire({
      title: 'Silmek istediğinize emin misiniz?',
      text: 'Bu işlem geri alınamaz!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#556EE6',
      cancelButtonColor: '#74788D',
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, silme.'
    })
    return swalRef;
  }

  showUndoAlert(): Promise<SweetAlertResult<any>>{
    const swalRef = Swal.fire({
      title: 'Geri yüklemek istediğinize emin misiniz?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#556EE6',
      cancelButtonColor: '#74788D',
      confirmButtonText: 'Evet, Geri Yükle!',
      cancelButtonText: 'Hayır, Yükleme.'
    })
    return swalRef;
  }

  showConfirmAlert(text: string = "İşleme devam etmek istiyor musunuz?", confirmButtonText: string = "Devam Et", cancelButtonText: string = "Geri Dön", isHtml: boolean = false): Promise<SweetAlertResult<any>>{

    if(isHtml)
      return Swal.fire({
        title: 'Uyarı',
        html: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#556EE6',
        cancelButtonColor: '#74788D',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
      });
    else
      return Swal.fire({
      title: 'Uyarı',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#556EE6',
      cancelButtonColor: '#74788D',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    });
  }
}
