<div *ngIf="model">
  <div class="card">
    <div class="card-body" *ngIf="showFilterAreas && model.data">
      <div class="card-title d-flex justify-content-between">
        <h5> Filtrele <i class="fas fa-search"></i> </h5>
        <ng-container *ngIf="pageFilterAreas.clearAll">
          <button class="btn btn-sm btn-rounded-1 btn-outline-info" triggers="hover" ngbTooltip="Filtreyi Temizle"
            (click)="clearFilter()"><i class="fas fa-trash-alt"></i></button>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.currency">
          <select class="form-control" [(ngModel)]="request.filter.currency" (change)="currencyChanged()">
            <option [ngValue]="defaultValue">Birim</option>
            <option *ngFor="let currency of currencyList" [ngValue]="currency"> {{currency | customCurrency}} </option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.subType && subTypeList?.length > 0">
          <select class="form-control" [(ngModel)]="request.filter.subType" (change)="reloadData(true)">
            <option [ngValue]="defaultValue">Alt Birim</option>
            <option *ngFor="let subType of subTypeList" [ngValue]="subType"> {{subType | subTypeDisplay}} </option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.amount">
          <input class="form-control" type="number" placeholder="Tutar" [(ngModel)]="request.filter.amount" id="amount"
            (keydown)="amountChanged($event)" />
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.case">
          <select aria-placeholder="Kasa" id="case" [(ngModel)]="request.filter.caseId"
            (ngModelChange)="reloadData(true)" class="form-control">
            <option [ngValue]="null">Kasa</option>
            <option *ngFor="let item of branchCases" [value]="item.value">{{item.text}} </option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.state">
          <select id="type" [(ngModel)]="request.filter.type" (change)="reloadData(true)" class="form-control">
            <option [ngValue]="defaultValue">Durum</option>
            <option *ngFor="let item of accountTransactionTypes" [ngValue]="item">{{item | transactionTypeText}}
            </option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.expenses">
          <select aria-placeholder="Masraf" id="expense" [(ngModel)]="request.filter.expenseTypeId"
            (ngModelChange)="reloadData(true)" class="form-control">
            <option [ngValue]="null">Masraf</option>
            <option *ngFor="let item of expenseList" [value]="item.value">{{item.text}} </option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.creater">
          <select id="creater" aria-placeholder="İşlem Yapan" [(ngModel)]="request.filter.createrId"
            (change)="reloadData(true)" class="form-control">
            <option [ngValue]="defaultValue">İşlemi Yapan</option>
            <option *ngFor="let item of applicationUsers" [value]="item.value">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.startDate">
          <div class="input-group">
            <label for="startDate"></label>
            <input id="startDate" (click)="beginDate.toggle()" class="form-control" name="beginDatePicker"
              [(ngModel)]="beginDateModel" (ngModelChange)="reloadData(true)" ngbDatepicker #beginDate="ngbDatepicker"
              autocomplete="off" placeholder="Başlangıç Tar.">
            <button class="btn border-start-0" style="border-color: #ced4da;" (click)="beginDate.toggle()"
              type="button"> <i class="far fa-calendar"></i> </button>
          </div>
        </div>
        <div class="col-md-2 mb-3" *ngIf="pageFilterAreas.endDate">
          <div class="input-group">
            <input (click)="endDate.toggle()" class="form-control" name="endDatePicker" [(ngModel)]="endDateModel"
              ngbDatepicker #endDate="ngbDatepicker" (ngModelChange)="reloadData(true)" autocomplete="off"
              placeholder="Bitiş Tar.">
            <span class="input-group-append">
              <button class="btn border-start-0" style="border-color: #ced4da;" (click)="endDate.toggle()"> <i
                  class="far fa-calendar-alt"></i> </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body p-0 ">
      <div class="row">
        <list-spinner class="center-body" [ngClass]="{'position-absolute': model.data.Items.length > 0}"
          [showSpinner]="Loading" [length]="model.data.Items.length"></list-spinner>
        <div class="table-responsive" *ngIf="model.data && model.data.Items.length > 0"
          [ngClass]="{'blur-content': Loading}">
          <table class="table table-striped mb-0">
            <thead>
              <tr>
                <th class="text-center">Bilgi</th>
                <th class="text-center" *ngIf="showSelectionField">Seçim Yap</th>
                <th class="text-center" *ngIf="showBalanceField">Bakiye</th>
                <th>Tutar</th>
                <th>Birim</th>
                <th>Milyem</th>
                <th *ngIf="showCaseField">Kasa</th>
                <!-- <th>Tipi</th> -->
                <th>Oluşt. Nedeni</th>
                <th>Tarih</th>
                <th class="text-center">Geçmiş</th>
                <th class="text-center" *hasRole="[Role.Admin, Role.SuperAdmin]">İşlem</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of model.data.Items; let index = index">
                <!--Shared popover-->
                <ng-template #popDescription><span> {{item.description}} </span></ng-template>
                <td *ngIf="item.reason == reasonEnum.Virman" class="text-center text-muted">
                  <i [ngbPopover]="popDescription" container="body" popoverTitle="Virman" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                </td>
                <td *ngIf="item.reason == reasonEnum.BranchTransfer" class="text-center text-muted">
                  <i [ngbPopover]="popDescription" container="body" popoverTitle="Açıklama" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                </td>
                <td *ngIf="item.reason == reasonEnum.Deposit || item.reason == reasonEnum.Withdraw"
                  class="text-center text-muted">
                  <i [ngbPopover]="popDescription" container="body" popoverTitle="Açıklama" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                </td>
                <td *ngIf="item.reason == reasonEnum.BranchMoneyExit" class="text-center text-muted">
                  <i [ngbPopover]="popExpense" container="body" popoverTitle="Açıklama" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                  <ng-template #popExpense>
                    <span><strong class="me-2">Masraf Türü:</strong><span *ngIf="item.expenseTypeName">
                        {{item.expenseTypeName}}</span><span *ngIf="!item.expenseTypeName">Seçilmedi.</span> </span>
                    <ng-container *ngIf="item.description">
                      <br>
                      <span>{{item.description}} </span>
                    </ng-container>
                  </ng-template>
                </td>
                <td *ngIf="item.reason == reasonEnum.BranchMoneyEntry" class="text-center text-muted">
                  <i [ngbPopover]="popExpense" container="body" popoverTitle="Açıklama" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                  <ng-template #popExpense>
                    <span> <strong class="me-2">Gelir Türü:</strong><span
                        *ngIf="item.expenseTypeName">{{item.expenseTypeName}}</span><span
                        *ngIf="!item.expenseTypeName">Seçilmedi.</span></span>
                    <ng-container *ngIf="item.description">
                      <br>
                      <span>{{item.description}} </span>
                    </ng-container>
                  </ng-template>
                </td>
                <td
                  *ngIf="item.reason == reasonEnum.ProductionCost || item.reason == reasonEnum.RawMaterialPurchase || item.reason == reasonEnum.ProductPurchase"
                  class="text-center text-muted">
                  <i [ngbPopover]="popCostPurchase" container="body" popoverTitle="Müşteri" placement="top"
                    class="fas fa-info-circle fa-2xl" *ngIf="item.customerId"></i>
                  <i [ngbPopover]="popCostPurchaseWithGetCustomer" container="body" popoverTitle="Müşteri"
                    placement="top" class="fas fa-info-circle fa-2xl" (click)="getCustomerByGroupId(item.groupId)"
                    *ngIf="!item.customerId"></i>
                  <ng-template #popCostPurchase>
                    <a href="javascript: void(0)" routerLink="/customer/detail/{{ item.customerId }}">
                      {{item.customerName}} </a>
                  </ng-template>
                  <ng-template #popCostPurchaseWithGetCustomer>
                    <a *ngIf="customerOfDetail" href="javascript: void(0)"
                      routerLink="/customer/detail/{{ customerOfDetail.id }}"> {{customerOfDetail.displayName}} </a>
                  </ng-template>
                </td>
                <td *ngIf="item.reason == reasonEnum.Collection" class="text-center text-muted">
                  <i [ngbPopover]="popCollection" container="body" popoverTitle="Açıklama" placement="top"
                    class="fas fa-info-circle fa-2xl" (click)="getCaseNameById(item)"></i>
                  <ng-template #popCollection>
                    <div class="m-1 fs-6">
                      <span class="fw-bold fs-6 me-1"> Müşteri: </span> <a href="javascript: void(0)"
                        routerLink="/customer/detail/{{ item.customerId }}"> {{item.customerName}} </a>
                    </div>
                    <div class="m-1" *ngIf="collectionCaseName != null">
                      <span class="fw-bold fs-6 me-1">Kasa: </span> <span> {{collectionCaseName}} </span>
                    </div>
                    <div class="m-1" *ngIf="item.description">
                      <span>{{item.description}}</span>
                    </div>
                  </ng-template>
                </td>
                <td *ngIf="item.reason == reasonEnum.Exchange" class="text-center text-muted">
                  <i (click)="getExchangeDetails(item.exchangeId)" [ngbPopover]="popExchange"
                    popoverClass="my-custom-class" container="body" [popoverTitle]="exchangeTitle" placement="top"
                    class="fas fa-info-circle fa-2xl"></i>
                  <ng-template #exchangeTitle><a href="javascript: void(0)"
                      routerLink="/exchange/detail/{{ item.exchangeId }}"> Alım/Satım </a></ng-template>
                  <ng-template #popExchange class="p-0">
                    <div class="d-flex justify-content-between mb-2">
                      <div>
                        <span class="me-1"> <strong>Müşteri:</strong> <a class="ms-1" href="javascript: void(0)"
                            routerLink="/customer/detail/{{ item.customerId }}">{{item.customerName}}</a> </span>
                      </div>
                      <div>
                        <span> <strong>Durum:</strong> <span class="ms-1"> {{exchangeInfo.state | exchangeState}}
                          </span></span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div>
                        <span class="me-1"> <strong>Satış:</strong> {{exchangeInfo.paymentCaseName}} </span>
                      </div>
                      <div>
                        <span class="me-1"> <strong>Alış:</strong> {{exchangeInfo.exchangeCaseName}} </span>
                      </div>
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="table-danger">Satış</th>
                          <!-- <th class="table-danger">Kasa</th> -->
                          <th class="table-info">Kur</th>
                          <th class="table-success">Alış</th>
                          <!-- <th class="table-success">Kasa</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> {{exchangeInfo.exchangeAmount | currencyAmount: exchangeInfo.exchangeCurrency}} <span
                              class="me-1" *ngIf="exchangeInfo.exchangeSubType"> ({{ exchangeInfo.exchangeSubType |
                              subTypeDisplay }})</span> {{exchangeInfo.exchangeCurrency | customCurrency}}</td>
                          <!-- <td> {{exchangeInfo.exchangeCaseName}}</td> -->
                          <td> {{exchangeInfo.parity | currencyAmount: null: null: true}} </td>
                          <td> {{exchangeInfo.paymentAmount | currencyAmount: exchangeInfo.paymentCurrency}} <span
                              class="me-1" *ngIf="exchangeInfo.paymentSubType"> ({{ exchangeInfo.paymentSubType |
                              subTypeDisplay }})</span> {{exchangeInfo.paymentCurrency | customCurrency}} </td>
                          <!-- <td> {{exchangeInfo.paymentCaseName}}</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </td>

                <td *ngIf="item.reason == reasonEnum.Sales" class="text-center text-muted">
                  <i [ngbPopover]="popSales" (click)="getSales(item.salesId)" container="body" popoverTitle="Açıklama"
                    placement="top" class="fas fa-info-circle fa-2xl"></i>
                  <ng-template #popSales>
                    <div class="m-1 fs-6" *ngIf="salesDetail && salesDetail.customerName">
                      <span class="fw-bold fs-6 me-1"> Müşteri: </span> <a href="javascript: void(0)"
                        routerLink="/customer/detail/{{ salesDetail.customerId }}"> {{salesDetail.customerName}} </a>
                    </div>
                    <div class="m-1" *ngIf="salesDetail && salesDetail.salesPrice">
                      <span class="fw-bold fs-6 me-1">Satış Fiyatı: </span> <span> {{salesDetail.salesPrice |
                        currencyAmount: null: null: true}} {{salesDetail.salesCurrency | priceCurrency:
                        salesDetail.salesSubType}} </span>
                    </div>
                    <div class="m-1" *ngIf="salesDetail && salesDetail.paidAmount">
                      <span class="fw-bold fs-6 me-1">Ödenen Miktar: </span> <span> {{salesDetail.paidAmount |
                        currencyAmount: null: null: true}} {{salesDetail.salesCurrency | priceCurrency:
                        salesDetail.salesSubType}} </span>
                    </div>
                    <div class="m-1" *ngIf="salesDetail && item.salesId">
                      <a class="fw-bold fs-6 me-1" href="javascript: void(0)"
                        routerLink="/fast-selling/detail/{{ item.salesId }}"> Detaya Git </a>
                    </div>
                  </ng-template>
                </td>
                <td *ngIf="showSelectionField" class="text-center">
                  <input
                  class="form-check-input"
                  type="checkbox"
                  [ngModel]="selectedTransactionForReceiptIndex === index"
                  (change)="addSelections($event, item)">
                </td>
                <td *ngIf="showBalanceField" class="text-center">
                  <!-- <input class="form-check-input" type="checkbox" [(ngModel)]="item.dateSelectedForBalance" (ngModelChange)="getItemForBalance(item)"  value="1" name="checkbox" checked> -->
                  <input class="form-check-input" type="checkbox" [ngModel]="selectedIndex === index"
                    (change)="changeSelection($event, index, item)">
                </td>
                <td><span *ngIf="item.type == Type.Receivable" class="fw-bold"> + </span><span
                    *ngIf="item.type == Type.Debt" class="fw-bold"> - </span>
                  {{ item.amount | currencyAmount : item.currency : item.subType: true }}</td>
                <td>{{ item.currency | customCurrency }}
                  <span *ngIf="item.subType != undefined">({{ item.subType | subTypeDisplay }})</span>
                </td>
                <td>{{item.milyem}}</td>
                <td>{{item.caseName}} </td>
                <!-- <td innerHTML="{{ item.type | transactionType }}"></td> -->
                <td>{{ item.reason | reasonType }} </td>
                <td>{{ item.date | date:'dd.MM.y HH:mm' }} </td>
                <td class="text-center"><app-history [trackable]="item"></app-history> </td>
                <td class="text-center"> <button type="button" *hasRole="[Role.User]" class="btn btn-sm btn-danger"
                    (click)="delete(item.id)"> <i class="text-white fas fa-trash-alt"></i> </button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <app-pagination [blurOn]="Loading" [pageInfo]="model.data" [pageRequest]="request"
            (requestEvent)="reloadData()">
          </app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
